<template>
  <div>
    <div class="container">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <p class="control">
              <span class="select is-small">
                <select v-model="year" v-on:change="refreshBalances">
                  <option value="2017">Année 2017</option>
                  <option value="2018">Année 2018</option>
                  <option value="2019">Année 2019</option>
                  <option value="2020">Année 2020</option>
                  <option value="2021">Année 2021</option>
                  <option value="2022">Année 2022</option>
                  <option value="2023">Année 2023</option>
                  <option value="2024">Année 2024</option>
                  <option value="2025">Année 2025</option>
                </select>
              </span>
            </p>
          </div>
          <div class="field">
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  value="by_year"
                  v-model="mode"
                  v-on:change="refreshBalances"
                />
                By year
              </label>
              <label class="radio">
                <input
                  type="radio"
                  value="by_month"
                  v-model="mode"
                  v-on:change="refreshBalances"
                  checked
                />
                By month
              </label>
            </div>
          </div>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Category</th>
            <th v-if="mode == 'by_month'">Month</th>
            <th>Negative</th>
            <th>Positive</th>
            <th>Sum</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="category in data"
            :key="category.category_id + category.month"
          >
            <th>{{ category.category_name }}</th>
            <td v-if="mode == 'by_month'">
              <span v-bind:inner-html.prop="category.month"></span>
            </td>
            <td class="balance-value">
              <span
                v-bind:inner-html.prop="withComma(category.negative)"
              ></span>
            </td>
            <td class="balance-value">
              <span
                v-bind:inner-html.prop="withComma(category.positive)"
              ></span>
            </td>
            <td class="balance-value">
              <span v-bind:inner-html.prop="withComma(category.sum)"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import RestResource from "../services/hackount";

export default {
  data() {
    return {
      year: 2025,
      data: [],
      mode: "by_month",
    };
  },
  methods: {
    async getData() {
      const rr = new RestResource();
      const categories = await rr
        .getHTTP()
        .get(`/api/reports/${this.year}/categories`, {
          params: { mode: this.mode },
        });
      this.data = categories.data.items;
    },
    withComma(value) {
      if (!value) return value;
      return value.replace(".", ",");
    },
    async refreshBalances() {
      await this.getData();
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style lang="scss">
table td.balance-value {
  text-align: end;
}
</style>
