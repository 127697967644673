<template>
  <div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div v-if="!rowData.parent_id" class="field has-addons">
          <p class="control">
            <a class="button is-small is-static">€</a>
          </p>
          <p class="control">
            <input
              class="input is-small"
              type="text"
              placeholder="Amount of money"
              v-model="amount"
            />
          </p>
          <p class="control">
            <button class="button is-small" @click="splitAmount">
              Split amount
            </button>
          </p>
        </div>
        <div v-else class="field">
          <button class="button is-danger is-small" @click="deleteRecord">
            Delete record
          </button>
        </div>
        <div v-if="!rowData.description" class="field has-addons">
          <p class="control">
            <input
              class="input is-small"
              type="text"
              placeholder="Description"
              v-model="description"
            />
          </p>
          <p class="control">
            <button class="button is-small" @click="setDescription">
              Set description
            </button>
          </p>
        </div>
        <div v-else class="field">
          <button class="button is-danger is-small" @click="resetDescription">
            Reset description
          </button>
        </div>
      </div>
    </div>
    <div v-if="rowData.description" class="inline field">
      <label><strong>Description: </strong></label>
      <span>{{ rowData.description }}</span>
    </div>
    <div class="inline field">
      <label><strong>Label: </strong></label>
      <span>{{ rowData.label }}</span>
    </div>
    <div class="inline field">
      <label><strong>Notes: </strong></label>
      <span>{{ rowData.notes }}</span>
    </div>
    <div class="inline field">
      <label><strong>Balance after operation: </strong></label>
      <span>{{ rowData.balance_after_operation }}</span>
    </div>
  </div>
</template>

<script>
import RestResource from "../services/hackount";

export default {
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
  },
  data: () => ({
    categories: [],
    errors: [],
    amount: null,
    description: null,
  }),
  methods: {
    async splitAmount() {
      const rr = new RestResource();
      await rr
        .getHTTP()
        .post(`/api/records/${this.rowData.id}/split`, this.amount, {
          headers: { "Content-Type": "application/json" },
        });
      this.$events.fire("table-reload");
    },
    async deleteRecord() {
      const rr = new RestResource();
      await rr.getHTTP().delete(`/api/records/${this.rowData.id}`);
      this.$events.fire("table-reload");
    },
    async setDescription() {
      const rr = new RestResource();
      await rr
        .getHTTP()
        .post(
          `/api/records/${this.rowData.id}/description`,
          JSON.stringify(this.description),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
      this.$events.fire("table-reload");
    },
    async resetDescription() {
      const rr = new RestResource();
      await rr
        .getHTTP()
        .post(`/api/records/${this.rowData.id}/description`, null, {
          headers: { "Content-Type": "application/json" },
        });
      this.$events.fire("table-reload");
    },
    onClick(event) {
      console.log("my-detail-row: on-click", event.target);
    },
  },
};
</script>
